<template>
  <div>
    <div class="d-flex align-items-center bg-light manage-header">
      <h4 class="m-0">Manage</h4>
    </div>
    <div class="border-top"></div>

    <ErrorDisplay :value="error" class="m-3"/>

    <div v-if="loading" class="text-center text-secondary py-2">
      <b-spinner small/>
    </div>

    <b-list-group v-else flush class="border-bottom mb-3">
      <b-list-group-item
        v-for="(item, key) in items"
        :key="key"
        :to="`/manage/${key}`"
        active-class="active"
      >{{typePlural(key)}}</b-list-group-item>
      <b-list-group-item
        v-if="items"
        to="/manage/image"
        active-class="active"
      >Images</b-list-group-item>
      <b-list-group-item
        v-if="items"
        to="/manage/payment-due"
        active-class="active"
      >Payments Due</b-list-group-item>
      <b-list-group-item
        v-if="items"
        to="/manage/artist-payment"
        active-class="active"
      >Artist Payments</b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { omit } from 'lodash'

import { http } from '@/services/http.js'
import { typePlural } from '@/pages/_type/index.vue'

export default {
  name: 'ManageMenu',
  components: {
  },
  data() {
    return {
      error: null,
      blacklist: [
        'sample-image',
        'artist-payment',
      ],
      items: null,
      loading: null,
    }
  },
  async created() {
    this.loading = true
    this.items = await http.get(`api/`)
      .then(response => response.data)
      .then(data => omit(data, this.blacklist))
      .catch(err => {
        this.error = err
        throw err
      })
      .finally(() => this.loading = false)
  },
  methods: {
    typePlural,
  },
}
</script>
