<template>
    <div>
      <slot name="title">
        <h1 class="h3 mb-4">Sign In</h1>
      </slot>
      <OptionsFormFields
        :set-item="login"
        @success="onSuccess($event)"
        v-model="formData"
        :fields="fields"
        label-cols-sm="0"
        label-cols-lg="0"
        label-required=""
        class="text-left"
      >
        <template #button-sibling>
          <router-link :to="{name: 'accounts-reset-password', params: {email: formData.username}, query: {redirect: redirect}}" class="btn btn-link mr-2">Forgot password?</router-link>
        </template>
        <template #button-content>
          Sign in
        </template>
      </OptionsFormFields>
      <slot name="footer">
        <hr>
        <div>
          New to Great American Art?
          <a :href="registerUrl">Sign up</a>
        </div>
      </slot>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import OptionsFormFields from '@/components/OptionsFormFields.vue'
import { API_URL, UI_URL } from '@/services/constants.js'

export default {
  name: 'AccountsLogin',
  components: {
    OptionsFormFields,
  },
  props: {
    redirect: String,
    onSuccess: {
      type: Function,
      default() {
        this.$router.push(this.redirect || '/')
      },
    },
    email: String,
  },
  data() {
    return {
      fields: {
        username: {
          label: '',
          type: 'email',
          required: true,
          props: { placeholder: 'Email', autofocus: true },
        },
        password: {
          label: '',
          type: 'password',
          required: true,
          props: { placeholder: 'Password' },
        },
      },
      formData: {
        username: this.email,
      },
    }
  },
  computed: {
    registerUrl() {
      const redirect = this.redirect ? this.redirect.replace(/^\//, '') : ''
      return `${API_URL}accounts/register?redirect=${encodeURIComponent(UI_URL + redirect)}`
    },
  },
  methods: {
    ...mapActions('user', [
      'login',
    ]),
  },
}
</script>
