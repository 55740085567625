<template>
  <OptionsFormFields
    ref="form"
    :value="value_"
    :api="api"
    :fields="fields"
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="disabled || busy"
  >
    <!-- pass all slots from parent component -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </OptionsFormFields>
</template>

<script>
import { identity } from 'lodash'

import OptionsFormFields from '@/components/OptionsFormFields.vue'
import { http } from '@/services/http.js'

export default {
  name: 'OptionsFormApi',
  components: {
    OptionsFormFields,
  },
  inheritAttrs: false,
  props: {
    api: String,
    optionsApi: String,
    value: Object,
    disabled: Boolean,
    getFields: {
      type: Function,
      default() {
        return http.options(
          `/api/${this.optionsApi || this.api}/`
        ).then(response => {
          if (!response.data.actions) {
            this.$bvToast.toast('Showing unformatted data', {title: 'No fields', variant: 'warning'})
            return
          }
          return this.modifyFields(response.data.actions.POST)
        })
      },
    },
    modifyFields: {
      type: Function,
      default: identity, // e.g. fields => ({ ...fields, new_field: {} })
    },
  },
  data() {
    return {
      value_: this.value,
      fields: null,
      busy: false,
    }
  },
  watch: {
    api() {
      this.fetchFormFields()
    },
    optionsApi() {
      this.fetchFormFields()
    },
    value(val) {
      this.value_ = val
    },
  },
  async created() {
    await this.fetchFormFields()
  },
  methods: {
    onSubmit() { return this.$refs.form.onSubmit() },
    async fetchFormFields() {
      this.busy = true
      try {
        this.fields = await this.getFields()
      } catch (err) {
        this.$alert.persistent(`${err}\nPlease try reloading the page. Contact customer support if this persists.`, {variant: 'danger'})
        throw err
      } finally {
        this.busy = false
      }
    },
  },
}
</script>
