<template>
  <form
    @submit.stop.prevent="onSubmit"
  >
    <div class="input-group">
      <input type="search" class="form-control"
        ref="input"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="none"
        :placeholder="placeholder"
        v-model="value_"
        @search="onSubmit"
        @keydown.enter.prevent="onSubmit"
      >
      <div class="input-group-append">
        <button type="submit"
          class="btn btn-light border-muted"
          title="Search"
          aria-label="Search"
        >
          <IconSearch aria-hidden="true"/>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import IconSearch from '@primer/octicons/build/svg/search-16.svg'

export default {
  name: 'SearchSimple',
  components: {
    IconSearch,
  },
  props: {
    value: String,
    placeholder: String,
  },
  data() {
    return {
      value_: this.value,
    }
  },
  watch: {
    value(val) { this.value_ = val },
  },
  mounted() {
    if (this.value_) {
      this.$refs.input.focus()
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.value_)
    },
  },
}
</script>
