<template>
  <div>
    <div class="row no-gutters manage-layout">
      <div class="col-sm-3 sidebar d-none d-sm-block">
        <ManageMenu/>
      </div>
      <div class="col-sm-9 overflow-clip pb-chat">
        <transition :name="transitionName">
          <router-view :key="fullishPath"></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import SwipeDetection from '@/components/SwipeDetection.js'
import ManageMenu from '@/pages/manage/-menu.vue'

export default {
  name: 'Manage',
  components: {
    ManageMenu,
  },
  mixins: [
    SwipeDetection,
  ],
  data() {
    return {
      transitionName: 'slide-right',
    }
  },
  computed: {
    fullishPath() {
      return getFullPathExcludingDisplayParams(this.$route)
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split('/').filter(i => i).length
    const fromDepth = from.path.split('/').filter(i => i).length
    this.transitionName = toDepth < fromDepth ? 'slide-left' : 'slide-right'

    // transition on small screens only
    if (from.name === 'manage' || to.name === 'manage') {
      this.transitionName += '-xs'
    }

    // no transition

    if (toDepth === fromDepth // siblings
        || (from.path === to.path.replace(/\/edit$/, '')) // toggle edit mode on
        || (from.path.replace(/\/edit$/, '') === to.path) // toggle edit mode off
        || (toDepth > fromDepth && !to.path.match(from.path)) // deeper, but not a descendent
        || (toDepth < fromDepth && !from.path.match(to.path)) // less deep, but not an ancestor
    ) {
      this.transitionName = 'minimize-flicker'
    }

    if (this.isSwiping) {
      this.transitionName = 'minimize-flicker'
    }

    next()
  },
}

export const displayParams = ['columns']

export function getFullPathExcludingDisplayParams(route) {
  // exclude display-only query params from full path
  const url = new URL(route.fullPath, location.origin)
  displayParams.forEach(i => url.searchParams.delete(i))
  return url.pathname + url.search
}
</script>

<style lang="scss" scoped>
.manage-layout {
  min-height: calc(100vh - 56px);
}
.sidebar {
  border-right: 1px solid #ccc;
}
</style>
