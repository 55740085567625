// Detect if the user is swiping. This will be used to prevent transtions
// between routes during forward/back swipe navigation on touch screens.

export default {
  data() {
    return {
      isSwiping: false,
    }
  },
  created() {
    this.onTouchMove = () => {
      this.isSwiping = true
    }
    this.onTouchEnd = () => {
      setTimeout(() => {
        this.isSwiping = false
      }, 15)
    }
    document.addEventListener('touchmove', this.onTouchMove)
    document.addEventListener('touchend', this.onTouchEnd)
  },
  destroyed() {
    document.removeEventListener('touchmove', this.onTouchMove)
    document.removeEventListener('touchend', this.onTouchEnd)
  },
}
