<template>
  <div
    class="fill-color"
    :class="{dimmed: busy || (disabled && compact), 'text-truncate': compact}"
    v-b-tooltip.hover
    :title="(compact) ? tooltip : undefined"
  >
    <b-spinner small
      v-if="busy"
      class="align-middle"
    />
    <IconCheck
      v-else-if="active"
    />
    <IconCircle
      v-else
    />
    <span class="ml-2">{{label}}</span>
  </div>
</template>

<script>
import IconCircle from '@primer/octicons/build/svg/circle-16.svg'
import IconCheck from '@primer/octicons/build/svg/check-16.svg'
import { http } from '@/services/http.js'
import { getSrc } from '@/services/media.js'

export default {
  name: 'QuickMenuCollection',
  components: {
    IconCircle,
    IconCheck,
  },
  props: {
    item: Object,
    collection: Object,
    compact: Boolean,
  },
  data() {
    return {
      busy: false,
    }
  },
  computed: {
    disabled() {
      // Use the same property as vue-multiselect
      return this.collection?.$isDisabled
    },
    active() {
      return this.collection?.contents?.includes(this.item.id)
    },
    label() {
      return this.collection?.name
    },
    tooltip() {
      if (this.disabled) { return }
      return (this.active)
        ? `Remove from ${this.collection?.name} collection`
        : `Add to ${this.collection?.name} collection`
    },
  },
  methods: {
    async toggle() {
      if (this.disabled) { return }
      if (this.busy) { return }
      try {
        this.busy = true
        // Remove any persistent alerts
        this.$alert.reset()
        let response
        if (this.active) {
          // Remove from collection
          response = await http.patch(`/api/collections/${this.collection.id}/remove/`, {remove: [this.item.id]})
          this.toast(
            this.item,
            `Removed from ${this.collection.name} collection`,
            {variant: 'secondary', to: `/collections/${this.collection.id}`}
          )
        } else {
          // Add to collection
          response = await http.patch(`/api/collections/${this.collection.id}/prepend/`, {prepend: [this.item.id]})
          this.toast(
            this.item,
            `Added to ${this.collection.name} collection`,
            {variant: 'success', to: `/collections/${this.collection.id}`}
          )
        }
        // Update the parent data
        this.$emit('update:collection', response.data)
      } catch (err) {
        const message = err.response?.data?.detail
          || JSON.stringify(err.response?.data)
          || `${err}\nPlease try again. Contact customer support if this persists.`
        this.$alert.persistent(message, {variant: 'danger'})
      } finally {
        this.busy = false
      }
    },
    toast(item, message, options) {
      const image = this.$createElement('img', {attrs: {src: getSrc(item, 'thumb')}, class: 'toast-image mr-2'})
      this.$root.$bvToast.toast(
        [image, message],
        {variant: 'success', noCloseButton: true, solid: true, bodyClass: 'd-flex align-items-center text-reset', ...options}
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.dimmed {
  opacity: $btn-disabled-opacity;
}
</style>
