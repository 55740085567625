<template>
  <div class="container-fluid mt-nav mb-5 mb-chat site-width">
    <div class="d-flex flex-wrap align-items-center gap-2">
      <b-breadcrumb :items="breadcrumbs" class="basecrumb d-print-none"/>
      <SearchSimple class="ml-auto" :value="query.search" @submit="query = {...query, search: $event}"/>
    </div>
    <h1 class="mb-4">{{title}}</h1>
    <BasicList
      :key="$route.fullPath"
      :type="type"
      :query.sync="query"
      :id-key="idKey"
      :get-name="getName"
    />
  </div>
</template>

<script>
import { startCase } from 'lodash'

import BasicList from './-list.vue'
import SearchSimple from '@/components/SearchSimple.vue'

export default {
  name: 'BasicListPage',
  components: {
    BasicList,
    SearchSimple,
  },
  props: {
    type: String,
    idKey: null,
    getName: null,
  },
  computed: {
    title() {
      return typePlural(this.type)
    },
    breadcrumbs() {
      return [
        {text: '🏠', to: '/'},
        {text: this.title, active: true},
      ]
    },
    query: {
      get() {
        return this.$route.query
      },
      set(query) {
        this.$router.push({query})
      },
    },
  },
}

export function typeTitle(type) {
  const single = (type)?.replace(/s?$/, '')
  return startCase(single)
}
export function typePlural(type) {
  let plural
  if (type?.endsWith('y')) {
    plural = type.replace(/y$/, 'ies')
  } else {
    plural = (type)?.replace(/s?$/, 's')
  }
  return startCase(plural)
}
</script>
