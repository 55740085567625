<template>
    <div>
      <slot name="title">
        <h1 class="h3 mb-4" v-if="title">{{title}}</h1>
      </slot>
      <OptionsFormFields
        :set-item="setItem"
        @success="onSuccess"
        v-model="formData"
        :fields="fields"
        label-cols-sm="0"
        label-cols-lg="0"
        label-required=""
        class="text-left"
      >
          <template #button-sibling>
            <router-link
              v-if="!okOnly"
              :to="{name: 'accounts-login', params: {email: formData.username}, query: { redirect: redirect}}"
              class="btn btn-link mr-2"
            >Cancel</router-link>
          </template>
          <template #button-content>
            Sign up
          </template>
      </OptionsFormFields>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import OptionsFormFields from '@/components/OptionsFormFields.vue'

export default {
  name: 'AccountsRegister',
  components: {
    OptionsFormFields,
  },
  props: {
    redirect: String,
    login: {
      type: String,
      default: 'true',
    },
    title: {
      type: String,
      default: 'Sign up',
    },
    okOnly: Boolean,
    email: String,
  },
  data() {
    return {
      fields: {
        username: {
          label: '',
          type: 'email',
          required: true,
          props: { placeholder: 'Email', autofocus: true },
        },
        password: {
          label: '',
          type: 'password',
          required: true,
          props: { placeholder: 'Password' },
        },
      },
      formData: {
        username: this.email,
      },
    }
  },
  methods: {
    ...mapActions('user', {
      loginAction: 'login',
      register: 'register',
    }),
    async setItem(formData) {
      await this.register(formData)
      if (['true', '1'].includes(this.login)) {
        await this.loginAction(formData)
          .catch(err => {
            this.$router.push({name: 'accounts-login', query: {redirect: this.redirect}})
            throw err
          })
      }
    },
    onSuccess() {
      this.$router.push(this.redirect || '/')
    },
  },
}
</script>
