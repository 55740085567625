<template>
    <div class="login-width">
      <h1 class="h3">Forgot your password?</h1>
      <p>We can send you a link to reset it.</p>
      <OptionsFormFields
        class="mt-4 text-left"
        :set-item="setItem"
        @success="onSuccess"
        v-model="formData"
        :fields="fields"
        label-cols-sm="0"
        label-cols-lg="0"
        label-required=""
      >
          <template #button-sibling>
            <router-link
              :to="{name: 'accounts-login', params: {email: formData.email}, query: { redirect: redirect}}"
              class="btn btn-link mr-2"
            >Cancel</router-link>
          </template>
          <template #button-content>
            Request Reset
          </template>
      </OptionsFormFields>
    </div>
</template>

<script>
import OptionsFormFields from '@/components/OptionsFormFields.vue'

export default {
  name: 'AccountsResetPassword',
  components: {
    OptionsFormFields,
  },
  props: {
    redirect: String,
    email: String,
  },
  data() {
    return {
      fields: {
        email: {
          label: '',
          type: 'email',
          required: true,
          props: { placeholder: 'Email', autofocus: true },
        },
      },
      formData: {
        email: this.email,
      },
    }
  },
  methods: {
    setItem(formData) {
      return this.$store.dispatch('user/resetPassword', formData)
    },
    onSuccess() {
      this.$router.push({name: 'accounts-login', params: {email: this.formData.email}, query: {redirect: this.redirect}})
      this.$alert.persistent("Password reset requested. If you don't receive an email, you may not have an account at that address." , {variant: 'success'})
    },
  },
}
</script>
