<template>
  <ItemsApiGrid
    :get-items="getItems"
    :limit="limit"
    :offset="offset"
    #default="{items}"
  >
    <b-list-group>
      <b-list-group-item
        v-for="item in items"
        :key="item[idKey]"
        :to="`/${type}/${item[idKey]}`"
      >
        <span class="text-muted small mr-3">{{item[idKey]}}</span>
        <span>{{getName(item)}}</span>
      </b-list-group-item>
    </b-list-group>
  </ItemsApiGrid>
</template>

<script>
import { http2 } from '@/services/http.js'
import { APP_LIMIT_DEFAULT } from '@/services/constants.js'

export default {
  name: 'BasicList',
  props: {
    type: {
      type: String,
      required: true,
    },
    query: Object,
    idKey: {
      type: String,
      default: 'id',
    },
    getName: {
      type: Function,
      default: item => item?.name,
    },
  },
  computed: {
    limit() {
      return Number(this.query?.limit ?? APP_LIMIT_DEFAULT)
    },
    offset() {
      return Number(this.query?.offset ?? 0)
    },
  },
  methods: {
    getItems({ params: { offset, limit, ...params } = {} } = {}) {
      window.scrollTo(0, 0)
      const page = Math.floor(offset / limit) + 1 || 1
      return http2.get(`/api/v2/${this.type}/`, {params: {...this.query, ...params, page, page_size: limit}})
    },
  },
}
</script>

