<template>
  <b-dropdown
    ref="dropdown"
    size="sm"
    split
    dropup
    lazy
    right
    variant="dark"
    class="btns-shy"
    split-class="min-w-0 text-truncate"
    @show="$emit('update:active', true)"
    @hide="$emit('update:active', false)"
    @click.stop.prevent="onClick"
    @click.native.stop.prevent
  >
    <template #button-content>
      <component
        ref="splitButton"
        v-if="selected"
        :is="selected.is"
        v-bind="selected.props"
        v-on="selected.events"
        :item="item"
        compact
      />
      <span v-else title="Actions">
        Actions
      </span>
    </template>

    <template #default="{hide}">
    <b-dropdown-item-button
      @click.stop.prevent="go({is:'QuickMenuHide'})"
    >
      <QuickMenuHide :item="item" ref="hb"/>
    </b-dropdown-item-button>
    <b-dropdown-header>Static Items</b-dropdown-header>
    <b-dropdown-item-button
      v-for="button in staticItems"
      :key="button.id"
      @click.stop.prevent="go(button)"
    >
      <component
        :is="button.is"
        v-bind="button.props"
        :item="item"
      />
    </b-dropdown-item-button>

    <b-dropdown-header>Collections</b-dropdown-header>
    <b-dropdown-item-button
      v-for="button in collections?.slice(0, maxCollections).map(formatCollectionConfig)"
      :key="button.id"
      :disabled="button.props.collection?.$isDisabled"
      @click.stop.prevent="go(button)"
    >
      <QuickMenuCollection
        :collection="button.props.collection"
        v-on="button.events"
        :item="item"
      />
    </b-dropdown-item-button>
    <b-dropdown-form
      v-if="collections?.length > maxCollections"
      form-class="px-3"
    >
      <QuickMenuCollectionMultiselect
        @input="onCollection($event) && hide(true)"
        :options="collections"
        :item="item"
      />
    </b-dropdown-form>
    <b-dropdown-item-button
      @click.stop.prevent="go({is:'QuickMenuCollectionCreate'})"
    >
      <QuickMenuCollectionCreate
        :item="item"
      />
    </b-dropdown-item-button>
    </template>
  </b-dropdown>
</template>

<script>
import QuickMenuCollection from '@/components/QuickMenuCollection.vue'
import QuickMenuCollectionCreate from '@/components/QuickMenuCollectionCreate.vue'
import QuickMenuCollectionMultiselect from '@/components/QuickMenuCollectionMultiselect.vue'
import QuickMenuHide from '@/components/QuickMenuHide.vue'
import QuickMenuStatic from '@/components/QuickMenuStatic.vue'
import { canEdit } from '@/pages/collections/-info.vue'
import store from '@/store'

export default {
  name: 'QuickMenu',
  components: {
    QuickMenuCollection,
    QuickMenuCollectionCreate,
    QuickMenuCollectionMultiselect,
    QuickMenuHide,
    QuickMenuStatic,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      staticItems: [
        { id: 'static-gov',    is: 'QuickMenuStatic',     props: { collection: 'government' }},
        { id: 'static-com',    is: 'QuickMenuStatic',     props: { collection: 'commercial' }},
      ],
      maxCollections: 5,
    }
  },
  computed: {
    collections() {
      const route = this.$route
      return this.$store.state.cache.collections?.map(collection => ({
        ...collection,
        // Set disabled in QuickMenuCollection and QuickMenuCollectionMultiselect (via native vue-multiselect support)
        $isDisabled: route?.name === 'collections-detail' && collection?.id === Number(route?.params.id),
        // Add lock emoji for collecitons that cannot be edited by current user
        name: collection?.name + (canEdit(collection, this.$store.state.user.user) ? '' : ' 🔒'),
      }))
    },
    selected: {
      get() {
        const button = this.$store.state.appearance.searchAdminButton
        const collections = this.collections
        if (button?.is === 'QuickMenuCollection' && collections) {
          // Keep splitButton updated
          const collection = collections.find(c => c.id === button.props.collection.id)
          if (!collection) { return }
          return {...button, props: {
            ...button.props,
            collection,
          }}
        }
        return button
      },
      set(val) {
        this.$store.commit('appearance/setSearchAdminButton', val)
      },
    },
  },
  created() {
    this.$store.dispatch('cache/fetch', 'collections')
  },
  methods: {
    onClick() {
      if (this.selected) {
        this.$refs?.splitButton.toggle()
      } else {
        this.$refs?.dropdown.show()
      }
    },
    async go(button) {
      this.selected = button
      await this.$nextTick()
      await this.$refs.splitButton.toggle()
    },
    async onCollection(val) {
      await this.go(this.formatCollectionConfig(val))
      this.$store.dispatch('cache/refetch', 'collections')
    },
    formatCollectionConfig,
  },
}

export function formatCollectionConfig(collection) {
  return {
    id: collection.id,
    is: 'QuickMenuCollection',
    props: { collection },
    events: {
      'update:collection'(val) {
        return store.dispatch('cache/patch', {type: 'collections', items: [val]})
      },
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .dropdown-menu[x-placement^="top"] {
  z-index: ($zindex-fixed + 4); // above other results, above SiteNav
}

@mixin not-hover-focus-within {
  // IE11 ignores rules with :focus-within.
  // https://css-tricks.com/dont-comma-separate-focus-within-if-you-need-deep-browser-support/#comment-1750402
  &:not(:hover):not(:focus-within) {
    @content;
  }
  @media all and (-ms-high-contrast:none) {
    &:not(:hover) {
      @content;
    }
  }
}

.btns-shy {
  .info:not(.menu-active) & {
    @include not-hover-focus-within {
      /deep/ .btn {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

/deep/ .min-w-0 {
  // cause flexbox items to shrink when there is not enough space
  min-width: 0;
}
</style>
