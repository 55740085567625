<template>
  <Multiselect
    @input="$emit('input', $event)"
    :options="[...options].sort((a, b) => a.name.localeCompare(b.name))"
    track-by="id"
    label="name"
    :show-labels="false"
    placeholder="More"
  >
    <template #option="{option}">
      <IconCheck v-if="option?.contents?.includes(item.id)"/>
      <IconCircle v-else/>
      <!-- Prevent click from triggering the MediaGrid router-link -->
      <span @click.prevent class="ml-2" :class="{'stretched-link': !option?.$isDisabled}">{{option.name}}</span>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import IconCircle from '@primer/octicons/build/svg/circle-16.svg'
import IconCheck from '@primer/octicons/build/svg/check-16.svg'

export default {
  name: 'QuickMenuCollections',
  components: {
    Multiselect,
    IconCircle,
    IconCheck,
  },
  props: {
    options: Array,
    item: Object,
  },
}
</script>
