import { mapValues } from 'lodash'

export function fauxOptions(item) {
  return mapValues(item, (val, key) => ({
    type: getType(val),
    label: key,
    read_only: ['id', 'created', 'modified'].includes(key),
  }))
}

function getType(val) {
  const type = typeof val
  const types = {
    number: 'float',
    object: 'nested object',
  }
  return types[type] || type
}
