<template>
  <div class="d-flex flex-wrap align-items-center gap-2">
    <span>{{formatCount(item.contents.length, 'item')}}</span>

    <span class="separator">•</span>

    <span v-if="item.owner === user?.uuid" v-b-tooltip title="You own this">
      <IconPerson class="fill-color" width="1em" height="1em" viewBox="0 0 16 16"/>
    </span>

    <span v-if="!item.communal && user?.is_staff" v-b-tooltip title="Only editable by owner">
      <IconLock class="fill-color" width="1em" height="1em" viewBox="0 0 16 16"/>
    </span>

    <span v-if="!item.public" v-b-tooltip title="Only visible to staff">
      <IconOrganization class="fill-color" width="1em" height="1em" viewBox="0 0 16 16"/>
    </span>
  </div>
</template>

<script>
import IconLock from '@primer/octicons/build/svg/lock-16.svg'
import IconOrganization from '@primer/octicons/build/svg/organization-16.svg'
import IconPerson from '@primer/octicons/build/svg/person-16.svg'

import { formatCount } from '@/utils/count.js'

export default {
  name: 'CollectionsInfo',
  components: {
    IconLock,
    IconOrganization,
    IconPerson,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    user: Object,
  },
  methods: {
    formatCount,
  },
}

export function canEdit(item, user) {
  return item && ((item.communal && user?.is_staff) || item.owner === user?.uuid)
}
</script>

<style lang="scss" scoped>
.separator:last-child {
  display: none;
}
</style>
