<template>
  <OptionsForm
    ref="form"
    v-bind="$attrs"
    @submit="onSubmit"
    v-model="formData"
    @input="onInput"
    :idKey="idKey"
    :fields="fields"
    :errors.sync="errors_"
    :omitFields="omitFields"
    :busy="busy"
    :disabled="disabled"
  >
    <!-- pass all slots from parent component -->
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </OptionsForm>
</template>

<script>
import { identity, get } from 'lodash'
import OptionsForm from '@/components/OptionsForm.vue'
import { http } from '@/services/http.js'
import { omitBy } from '@/utils/omit.js'
import VAlert from '@/features/VAlert'

export default {
  name: 'OptionsFormFields',
  components: {
    OptionsForm,
  },
  inheritAttrs: false,
  props: {
    api: String,
    setItem: {
      type: Function,
      default(formData) {
        const id = formData[this.idKey]
        const requestData = this.modifyRequest(formData)
        return (id)
          ? http.put(`/api/${this.api}/${id}/`, requestData)
          : http.post(`/api/${this.api}/`, requestData)
      },
    },
    value: Object,
    errors: Object,
    fields: Object,
    omitFields: {
      type: Function,
      default() {}, // e.g. field => field.read_only
    },
    idKey: {
      type: String,
      default: 'id',
    },
    modifyErrors: {
      type: Function,
      default: identity, // e.g. errors => _.keyBy('fieldName')
    },
    modifyRequest: {
      type: Function,
      default: identity, // e.g. fields => ({ ...fields, new_field: {} })
    },
    disabled: Boolean,
  },
  data() {
    return {
      formData: this.value || getInitialValues(this.fields),
      errors_: this.errors,
      busy: false,
    }
  },
  watch: {
    fields(val) {
      this.formData = this.formData || getInitialValues(val)
    },
    value: {
      handler(val) {
        this.formData = val
      },
    },
    errors(val) {
      this.errors_ = val
    },
    busy(val) {
      this.$emit('update:busy', val)
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    },
    async onSubmit() {
      this.$alert.reset()
      if (!this.$refs.form.clientValidate()) {
        throw new Error('Form failed client-side validation')
      }
      this.busy = true
      const response = await this.setItem(this.formData).catch(err => {
        if (err.response && err.response.status === 400) {
          this.errors_ = this.modifyErrors(err.response.data)
        }
        onFormError(err, this.errors_, this.hasInvisibleErrors(this.errors_))
        throw err
      })
      .finally(() => this.busy = false)
      const data = response?.data
      if (data) { this.formData = data }
      this.$emit('success', data)
      return data
    },
    hasInvisibleErrors(data) {
      const { non_field_errors, ...rest } = data
      const visibleFields = omitBy(this.fields, this.omitFields)
      return extraKeys(visibleFields, rest)
    },
    clientValidate() {
      return this.$refs.form.clientValidate()
    },
  }
}

function getInitialValues(fields) {
  // Get initial values from fields object
  if (!fields) { return }
  return Object.keys(fields).reduce((acc, key) => {
    const val = fields[key].initial
    // Only set keys that have a value
    if (val !== undefined) {
      acc[key] = val
    }
    return acc
  }, {})
}

export function onFormError(err, errors, hasInvisibleErrors) {
  if (err.response && err.response.status === 400) {
    if (hasInvisibleErrors) {
      const msgInvalid = `Invalid hidden fields. Please contact support.\n${formatObject(errors, ' • p k: v\n')}`
      VAlert.persistent(msgInvalid, {variant: 'danger'})
    } else if (!errors || !Object.keys(errors).length) {
      VAlert.persistent(`${err}. Please contact support.`, {variant: 'danger'})
    }
  } else {
    const msgDetail = get(err, 'response.data.detail', err)
    VAlert.persistent(msgDetail, {variant: 'danger'})
  }
}

export function extraKeys(src = {}, dest = {}) {
  // return true if dest object has any keys that are not found in src
  return Object.keys(dest).some(i => !Object.keys(src).includes(i))
}

function isPlainObject(val) {
  return val?.toString?.() === '[object Object]'
}
function objectToString(val, prefix = '', dot = '.', colon = ': ', separator = ', ') {
  if (!val) { return }
  return Object.entries(val)
    .map(([k, v]) => {
      return isPlainObject(v)
        ? objectToString(v, `${prefix}${k}${dot}`, dot, colon, separator)
        : `${prefix}${k}${colon}${v}`
    })
    .join(separator)
}
function formatObject(val, template='p.k: v, ') {
  return objectToString(val, ...template.match(/^(.*)p(.*)k(.*)v([\s\S]*)$/).slice(1))
}
</script>
