import { memoize } from 'lodash'
import { http } from '@/services/http.js'

const state = {
  collections: null,
}

const dataFns = {
  default: (type) => http.get(`/api/${type}/`, {params: {limit: 1000, ordering: '-modified'}})
    .then(response => response.data.results),
}
function getData(type) {
  const fn = dataFns[type] || dataFns.default
  return fn(type)
}
async function uncachedFetch({ commit }, type) {
  const data = await getData(type)
  commit('setCache', { [type]: data })
  return data
}
const cachedFetch = memoize(uncachedFetch , (context, type) => type)

const actions = {
  fetch: cachedFetch,
  refetch(context, type) {
    cachedFetch.cache.delete(type)
    return cachedFetch(context, type)
  },
  patch({ commit, state }, { type, items }) {
    cachedFetch.cache.delete(type)
    commit('patchCache', { [type]: items })
    return state[type]
  },
  delete({ commit }, type) {
    cachedFetch.cache.delete(type)
    commit('setCache', { [type]: null })
  },
  clear({ commit }) {
    cachedFetch.cache.clear()
    commit('clear')
  },
}

const mutations = {
  setCache(state, data) {
    Object.keys(data).forEach(key => {
      state[key] = data[key]
    })
  },
  patchCache(state, data) {
    Object.keys(data).forEach(type => {
      const list = state[type]
      const items = data[type]
      if (!list) {
        state[type] = items
      } else if (Array.isArray(list) && Array.isArray(items)) {
        items.forEach(item => {
          const index = list.findIndex(i => i.id === item.id)
          if (index !== -1) {
            // replace existing items
            list.splice(index, 1, item)
          } else {
            // add new items to the beginning of the list
            list.unshift(item)
          }
        })
      } else {
        throw new Error(`Invalid patch`)
      }
    })
  },
  clear(state) {
    Object.keys(state).forEach(key => {
      state[key] = null
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}


