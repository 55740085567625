<template>
    <div>
      <h1 class="h3 mb-4">Set password</h1>
      <OptionsForm
        ref="form"
        @submit="onSubmit"
        v-model="formData"
        :fields="fields"
        :errors.sync="errors"
        label-cols-sm="0"
        label-cols-lg="0"
        label-required=""
      />
    </div>
</template>

<script>
import OptionsForm from '@/components/OptionsForm.vue'
import { onFormError, extraKeys } from '@/components/OptionsFormFields.vue'

export default {
  name: 'AccountsResetPasswordConfirm',
  components: {
    OptionsForm,
  },
  props: {
    redirect: String,
    uid: String,
    token: String,
  },
  data() {
    return {
      fields: {
        new_password: {
          label: '',
          type: 'password',
          required: true,
          props: { placeholder: 'New Password', autofocus: true },
        },
      },
      formData: {
        uid: this.uid,
        token: this.token,
      },
      errors: {},
    }
  },
  created() {
    this.checkMissingProps()
  },
  methods: {
    checkMissingProps() {
      const params = ['uid', 'token']
      const messages = params
        .map(i => this[i] ? null : i)
        .filter(i => i)
      if (messages.length) {
        this.$alert.persistent(
          `Missing ${messages.join(', ')}. Please contact support.`,
          {variant: 'danger'}
        )
      }
    },
    async onSubmit() {
      await this.$store.dispatch('user/resetPasswordConfirm', this.formData)
        .catch(err => {
          if (err.response) {
            this.errors = err.response.data
          }
          onFormError(err, this.errors, extraKeys(this.fields, this.errors))
          throw err
        })
      this.$router.push({name: 'accounts-login', query: {redirect: this.redirect}})
      this.$alert.simple('Password changed.', {variant: 'success'})
    },
  },
}
</script>
